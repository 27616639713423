<template>
  <nav class="mainNav">
    <h1>Глобус | Публикатор</h1>
    <telegram-icon></telegram-icon>

    <template v-if="!$route.meta?.isPublic">
      <div class="mainNav__separator"></div>

      <div class="mainNav__userMenu">
        <user-menu
          class="mainNav__userMenu"
          @click="showUserMenu = !showUserMenu"
        ></user-menu>
        <user-adv-menu v-model="showUserMenu"></user-adv-menu>
      </div>
      <div class="mainNav__burgerButton" @click.prevent="showMenuClickHandler">
        <svg
          width="29"
          height="29"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="mainNav__burgerIcon"
        >
          <g clip-path="url(#clip0_479_11023)">
            <path
              d="M7.27047 12.2163C5.96236 12.2163 4.89209 13.2866 4.89209 14.5947C4.89209 15.9028 5.96236 16.9731 7.27047 16.9731C8.57858 16.9731 9.64885 15.9028 9.64885 14.5947C9.64885 13.2866 8.57858 12.2163 7.27047 12.2163ZM21.5407 12.2163C20.2326 12.2163 19.1624 13.2866 19.1624 14.5947C19.1624 15.9028 20.2326 16.9731 21.5407 16.9731C22.8488 16.9731 23.9191 15.9028 23.9191 14.5947C23.9191 13.2866 22.8488 12.2163 21.5407 12.2163ZM14.4056 12.2163C13.0975 12.2163 12.0272 13.2866 12.0272 14.5947C12.0272 15.9028 13.0975 16.9731 14.4056 16.9731C15.7137 16.9731 16.784 15.9028 16.784 14.5947C16.784 13.2866 15.7137 12.2163 14.4056 12.2163Z"
            />
          </g>
          <defs>
            <clipPath id="clip0_479_11023">
              <rect
                x="0.135254"
                y="0.324219"
                width="28.5405"
                height="28.5405"
                rx="14.2703"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <menu-dialog v-if="showMenu" v-model="showMenu"></menu-dialog>
    </template>
  </nav>
</template>

<script lang="ts">
import TelegramIcon from "@/components/shared/Icons/TelegramIcon.vue";
import { computed, ref } from "vue";
import MenuDialog from "@/components/MenuDialog.vue";
import UserMenu from "@/components/shared/UserMenu.vue";
import UserAdvMenu from "@/components/shared/UserAdvMenu.vue";
import { useAuthStore } from "@/store/auth";

export default {
  name: "MainNav",
  components: { UserAdvMenu, UserMenu, MenuDialog, TelegramIcon },
  setup() {
    const authStore = useAuthStore();
    const showMenu = ref(false);
    const showUserMenu = ref(false);
    const isAuth = computed(() => authStore.getAuthToken());

    function showMenuClickHandler() {
      if (!authStore.getAuthToken()) {
        showMenu.value = false;
        return;
      }
      showMenu.value = true;
    }
    return { showMenu, showUserMenu, isAuth, showMenuClickHandler };
  },
};
</script>

<style scoped lang="scss">
.mainNav {
  display: flex;
  height: 84px;
  align-items: center;
  padding: 0 37px;
  margin: 0;
  gap: 8px;
  h1 {
    padding: 0;
    margin: 0;
  }
  &__separator {
    flex-grow: 1000;
  }
  &__userMenu {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  &__burgerButton {
    background-color: #eef1ff;
    width: 44px;
    height: 44px;
    cursor: pointer;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 28px;
      height: 28px;
    }
  }
  &__burgerIcon {
    path {
      fill: #a7a7a7;
    }
    &:hover {
      path {
        fill: #186afe;
      }
    }
  }
}
</style>
