<template>
  <a class="simpleButton" :class="colorButton" v-bind="$props">
    <slot></slot>
  </a>
</template>

<script lang="ts">
import { computed } from "vue";

export default {
  name: "SimpleButton",
  props: ["colorClass"],
  setup(props: { colorClass: string }) {
    const colorButton = computed(() => {
      return "simpleButton__" + props.colorClass || "white";
    });
    return { colorButton };
  },
};
</script>

<style lang="scss">
.simpleButton {
  text-decoration: none;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  line-height: 23px;
  font-size: 16px;
  font-weight: 400;
  padding: 20px;
  background-color: #fefefe;
  border: 1px solid #e0e0e0;
  color: #373d3f;
  min-width: 190px;
  cursor: pointer;
  &:hover {
    color: #518bfd;
    border: 1px #518bfd solid;
  }
  &:active {
    border: 1px solid #186afe;
    color: #186afe;
  }

  &__blue {
    color: white;
    background-color: #186afe;
    &:hover {
      color: white;
      svg {
        path {
          fill: white;
        }
      }
    }
  }
  &__violet {
    color: #373d3f;
    background-color: #eef1ff;
    border: none;
    &:hover {
      color: #373d3f;
      background-color: #cfddf6 !important;
      border: none;
      path {
        fill: #373d3f;
      }
    }
    &:active {
      border: none;
      background-color: #a0c0f9;
      color: #373d3f;
      transition: all 0.5s ease;
    }
  }
}
</style>
