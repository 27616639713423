<template>
  <div class="analyticsPage">
    <div class="bg-white">
      <tab-control
        :active-tab="selectedTabId"
        :tabs="tabs"
        @tabClick="tabClickHandler"
        :no-underline="true"
      ></tab-control>
    </div>
    <div class="analyticsPage__detail">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import TabControl from "@/components/shared/TabControl";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "ProfileStatisticPage",
  components: { TabControl },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const tabs = [
      { id: 1, name: "Моя аналитика", routeName: "PersonalAccountMyStatistic" },
      {
        id: 2,
        name: "Аналитика источников",
        routeName: "PersonalAccountSourcesStatistic",
      },
    ];
    const selectedTabId = ref(
      route.name === "PersonalAccountSourcesStatistic" ? 2 : 1
    );

    function tabClickHandler(tab) {
      selectedTabId.value = tab.id;
      const found = tabs.find((item) => item.id === tab.id);
      router.push({ name: found.routeName });
    }

    return { tabs, tabClickHandler, selectedTabId };
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/vars.scss";

$default-gap: 40px;

.analyticsPage {
  margin-left: $default-gap;
  margin-right: $default-gap;
  display: flex;
  flex-direction: column;
  gap: 0;
  background: white;

  &__detail {
    //border: 1px solid silver;
    min-height: calc(100vh - $header-height - $default-gap);
    overflow-y: auto;
    width: 100%;
    padding: 0;
  }
}
</style>
