import axios from "axios";
import { ref } from "vue";
import useCancelToken from "@/store/composables/cancel-token";

export default function useCrudStore(
  endpointParam = "",
  idFieldParam = "id",
  resultFieldParam = "",
  defaultRecordParam = {}
) {
  const cancelTokenStore = useCancelToken();
  const endpoint = ref(endpointParam);
  const error = ref("");
  const loading = ref(false);
  const items = ref([]);
  const record = ref({});
  const idField = ref(idFieldParam);
  const resultField = ref(resultFieldParam);
  const defaultRecord = ref(defaultRecordParam);
  const stateChanged = ref(0);
  const selectedId = ref(null);

  function triggerStatedChanged() {
    stateChanged.value++;
  }

  function startLoading() {
    loading.value = true;
    error.value = "";
  }

  function clearLoading() {
    loading.value = false;
    error.value = "";
  }

  function setError(e: any) {
    console.log("Set Error", e);
    if (axios.isCancel(e)) {
      return;
    }
    if (e.response) {
      console.log("Found response", e.response);
      e = String(e) + " " + JSON.stringify(e.response.data, null, 4);
    }
    error.value = e;
    loading.value = false;
  }

  function resetData() {
    items.value = null;
    loading.value = false;
    error.value = "";
  }

  function setRecord(newRecord: object) {
    record.value = JSON.parse(JSON.stringify(newRecord));
  }

  function setSelectedId(id: string) {
    selectedId.value = id;
  }

  function newRecord() {
    record.value = JSON.parse(JSON.stringify(defaultRecord.value));
  }

  function removeRecord(id: string) {
    const idFieldName = idField.value;
    items.value = (items.value || []).filter(
      (item) => item[idFieldName] !== id
    );
  }

  async function clearData() {
    resetData();
  }

  function updateItem(newRecord: any) {
    if (!items.value) {
      items.value = [];
    }
    const idFieldName = idField.value;
    const found = items.value.findIndex(
      (item) => item[idFieldName] === newRecord[idFieldName]
    );

    if (found < 0) {
      items.value.push(newRecord);
      return;
    }
    items.value[found] = newRecord;
  }

  function addItems(newItems?: any[]) {
    const idFieldName = idField.value;
    items.value = [
      ...(items.value || []).filter(
        (item) =>
          !(newItems || []).find(
            (item2) => item2[idFieldName] === item[idFieldName]
          )
      ),
      ...newItems,
    ];
  }

  async function baseLoad(payload?: any) {
    console.log("Base Load", endpoint.value);
    cancelTokenStore.acquireToken();
    const params = { ...(payload || {}) };
    startLoading();
    try {
      console.log("loading: ", endpoint.value);
      const response = await axios.get(endpoint.value, {
        params,
        cancelToken: cancelTokenStore.cancelToken.value,
      });
      // DEBUG console.log('result',response.data );
      const items = resultField.value
        ? response.data[resultField.value]
        : response.data;
      addItems(items);
      clearLoading();
      console.log("loaded");
    } catch (error) {
      setError(error);
    }
  }
  return {
    endpoint,
    error,
    loading,
    items,
    cancelTokenStore,
    record,
    idField,
    resultField,
    defaultRecord,
    stateChanged,
    selectedId,
    triggerStatedChanged,
    startLoading,
    clearLoading,
    setError,
    resetData,
    setRecord,
    setSelectedId,
    removeRecord,
    clearData,
    updateItem,
    addItems,
    baseLoad,
  };
}
