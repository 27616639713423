<template>
  <custom-right-dialog
    v-if="showDialog"
    width="250px"
    :background-cancel="true"
    :is-right="true"
    @cancel-click="showDialog = false"
    @ok-click="showDialog = false"
    border-radius="0"
    :use-buttons="false"
    :hide-background="true"
    :hide-border="true"
  >
    <div class="menuDialogList">
      <div
        class="menuDialogList__item"
        :class="{ menuDialogList__active: $route.name === item.routeName }"
        v-for="item in menuItems"
        :key="item.id"
        @click="itemClickHandler(item)"
      >
        <menu-dialog-items-icon
          :icon-name="item.routeName"
        ></menu-dialog-items-icon>
        {{ item.name }}
      </div>
    </div>
  </custom-right-dialog>
</template>

<script lang="ts">
import { computed } from "vue";
import MenuDialogItemsIcon from "@/components/MenuDialog/MenuDialogItemsIcon.vue";
import { useRouter } from "vue-router";
import CustomRightDialog from "@/components/shared/CustomeRightDialog.vue";

export default {
  name: "MenuDialog",
  props: ["modelValue"],
  emits: ["update:modelValue"],
  components: { CustomRightDialog, MenuDialogItemsIcon },
  setup(props: any, context: any) {
    const router = useRouter();

    const menuItems = [
      { id: 1, name: "Профиль и настройки", routeName: "Settings" },
      { id: 3, name: "Мои каналы", routeName: "MyChannels" },
      { id: 4, name: "Вернуться на главную", routeName: "Publish" },
      { id: 5, name: "Аналитика", routeName: "PersonalAccountMyStatistic" },
    ];

    const showDialog = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        context.emit("update:modelValue", value);
      },
    });

    function itemClickHandler(item: any) {
      router.push({ name: item.routeName });
      showDialog.value = false;
    }
    return { showDialog, menuItems, itemClickHandler };
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/vars.scss";

.menuDialogList {
  padding-top: 27px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  &__item {
    padding: 0 12px;
    line-height: 32px;
    display: flex;
    align-items: center;
    gap: 4px;
    color: #363636;
    font-size: 14px;
    font-family: $font-roboto-regular;
    font-weight: 400;
    border-radius: 8px;
    background-color: white;
    cursor: pointer;
    &:hover {
      background-color: #eef1ff;
    }
  }
  &__active {
    color: #186afe;
    background-color: #eef1ff;
    path {
      stroke: #186afe;
    }
  }
}
/* ---------------------------------- */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
