import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/styles/styles.scss";
import { axiosInit } from "@/tools/axios-init.tools";
import { BIconEye, BIconEyeSlash } from "bootstrap-icons-vue";

import vuePlayer from "@algoz098/vue-player";

import "flatpickr/dist/flatpickr.css";

import "@/components/editor/style.css";

const pinia = createPinia();
const app = createApp(App);

app.component("BIconEyeSlash", BIconEyeSlash);
app.component("BIconEye", BIconEye);
app.component("vuePlayer", vuePlayer);

app.use(router);
app.use(pinia);
// app.config.globalProperties.$oidc = mainOidc
axiosInit();
/*
if ("serviceWorker" in navigator) {
  window.addEventListener("load", function () {
    navigator.serviceWorker.register("/sw.js").then((data) => {
      environment.appConfig.serviceWorkerRegistration = data;
      const authStore = useAuthStore();
      const currentToken = authStore.getAuthToken();
      if (currentToken) {
        authStore.sendToWorker(currentToken);
      }
    });
  });
}
*/
console.log("[START] Publisher Application v ", process.env.VUE_APP_VERSION);
app.mount("#app");
