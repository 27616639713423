<template>
  <user-icon></user-icon>
</template>

<script lang="ts">
import UserIcon from "@/components/shared/Icons/UserIcon.vue";
export default {
  name: "UserMenu",
  components: { UserIcon },
};
</script>

<style scoped></style>
