import axios from "axios";
import { computed, ref } from "vue";

export default function useCancelTokenStore() {
  const cancelTokenSource = ref(null);

  const cancelToken = computed(() => {
    return cancelTokenSource.value?.token;
  });

  function releaseToken() {
    if (cancelTokenSource.value) {
      cancelTokenSource.value.cancel();
    }
    cancelTokenSource.value = null;
  }

  function acquireToken() {
    releaseToken();
    cancelTokenSource.value = axios.CancelToken.source();
  }

  return {
    cancelToken,
    releaseToken,
    acquireToken,
  };
}
