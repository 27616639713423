import { RouteRecordRaw } from "vue-router";
import ProfilePage from "@/components/PersonalAccountView/ProfilePage.vue";
import PersonalAccountServicesPage from "@/components/PersonalAccountView/ServicesPage.vue";

export const personalAccountRoutes: Array<RouteRecordRaw> = [
  {
    path: "main",
    name: "PersonalAccountMain",
    component: ProfilePage,
  },
  {
    path: "services",
    name: "PersonalAccountServices",
    component: PersonalAccountServicesPage,
  },
];
