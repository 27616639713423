import { defineStore } from "pinia";
import useCrudStore from "@/store/composables/crud";
import environment from "@/environment";
import useCancelTokenStore from "@/store/composables/cancel-token";
import axios from "axios";
import { PublicationCounter } from "@/model/publication-counter";

export const useViewStatisticStore = defineStore("view-statistic", () => {
  const crud = useCrudStore(
    environment.appConfig.baseApiUrl + "/statistic/publications/view_count"
  );
  const cancelToken = useCancelTokenStore();

  async function loadViewCounters(
    startDate: string,
    channelIds: string[] = null
  ) {
    console.log("loadViewCounters", startDate);
    cancelToken.acquireToken();
    crud.startLoading();
    const params = {
      start_date: startDate,
    };
    try {
      const resp = await axios.get(crud.endpoint.value, {
        cancelToken: cancelToken.cancelToken.value,
        params,
      });
      const items = resp?.data?.items;
      console.log("items", items);
      const arr: PublicationCounter[] = [];
      for (const channelId in items) {
        // Фильтрую по каналам
        if (channelIds?.length && !channelIds.find((id) => id == channelId)) {
          continue;
        }
        if (+items[channelId] === 0) {
          continue;
        }
        arr.push({ channelId, count: +items[channelId] });
      }
      crud.addItems(arr);
      crud.clearLoading();
    } catch (e) {
      crud.setError(e);
    }
    //console.log("arr", arr);
  }
  return {
    ...crud,
    loadViewCounters,
  };
});
