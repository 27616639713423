<template>
  <site-layout></site-layout>
</template>

<style lang="scss">
#app {
  font-family: -apple-system, Roboto, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
::-webkit-scrollbar {
  width: 14px;
  background-color: white;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #293f6b;
  border-radius: 14px;
  width: 4px;
  border: 3px solid white;
}
</style>
<script>
import SiteLayout from "@/components/SiteLayout.vue";
import { onMounted } from "vue";
export default {
  components: { SiteLayout },
  setup() {
    onMounted(() => {
      document.title = "Публикатор";
    });
    return {};
  },
};
</script>
