import { defineStore } from "pinia";
import useCrudStore from "@/store/composables/crud";
import environment from "@/environment";
import useCancelTokenStore from "@/store/composables/cancel-token";
import axios from "axios";
import { Channel } from "@/model/channel";

export const useChannelStore = defineStore("channels", () => {
  const crud = useCrudStore(
    environment.appConfig.baseApiUrl + "/user/channels"
  );
  const cancelToken = useCancelTokenStore();

  async function loadChannelImage(channel_id: string): Promise<string> {
    const found = crud.items.value.find((item) => item.id == channel_id);
    if (!found) {
      console.log("channel not found", channel_id);
      return null;
    }
    if (!found.photo_id) {
      return null;
    }
    if (found.url && found.url !== "null") {
      console.log("channel url loaded", channel_id);
      return found.url;
    }
    try {
      const resp = await axios.get(
        environment.appConfig.baseApiUrl +
          `/tg/download/?file_id=${found.photo_id}`
      );
      found.url = resp.data;
      return resp.data;
    } catch (e) {
      console.error(e);
    }
    return "";
  }

  async function loadChannels(withPictures = true) {
    cancelToken.acquireToken();
    crud.startLoading();
    try {
      console.log("Channels data is Loading");
      const params = {};
      const items: Channel[] = (
        await axios.get(crud.endpoint.value, {
          cancelToken: cancelToken.cancelToken.value,
          params,
        })
      ).data;

      if (withPictures) {
        // Получаю картинки
        const itemsWithPhoto = items.filter((item) => item.photo_id);
        const requests = itemsWithPhoto.map((item) =>
          axios
            .get(
              environment.appConfig.baseApiUrl +
                `/tg/download/?file_id=${item.photo_id}`,
              { cancelToken: cancelToken.cancelToken.value }
            )
            .catch((error) => {
              console.log("error");
              return { error };
            })
        );

        const responses = await axios.all(requests);

        responses.forEach((response, index) => {
          let resp: any = response;
          if (resp.response) {
            resp = resp.response;
          }
          itemsWithPhoto[index].url = resp.data;
        });
      }

      items.forEach((item) => (item.enabled = true));
      crud.addItems(items);
      crud.clearLoading();

      console.log("Channels data was Loaded");
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }
      crud.setError(error);
    }
  }
  return {
    loadChannels,
    ...cancelToken,
    ...crud,
    loadChannelImage,
  };
});
