<template>
  <div class="myStatistic">
    <!-- pre>
      {{ publicationCountersStore.loading }}
      {{ followersStore.loading }}
      {{ viewStore.loading }}
      {{ publicationsStore.loading }}
      {{ channelStore.loading }}
    </pre -->

    <tab-control
      :active-tab="activePeriod"
      :tabs="tabs"
      @tabClick="tabChangeHandler"
    ></tab-control>
    <div class="myStatistic__container">
      <div class="myStatistic__row">
        <div class="myStatistic__countersPlate">
          <!-- Выбор канала -->
          <drop-button-selector
            :options="myChannelStore.items"
            v-model="myChannelStore.selectedChannel"
            class="channelSelect"
          ></drop-button-selector>
          <!-- Счетчики -->
          <span class="myStatistic__counters">
            <span class="myStatistic__counterValue">{{ publishedCount }}</span>
            <span class="myStatistic__counterInfo">Новостей опубликовано</span>
            <span class="myStatistic__counterValue"> {{ viewedCount }} </span>
            <span class="myStatistic__counterInfo">Количество просмотров</span>
          </span>
        </div>
        <!-- -->

        <div class="myStatistic__diagram myStatistic__bigDiagram">
          <div class="myStatistic__header1">Число публикаций</div>
          <apexcharts
            width="100%"
            type="bar"
            height="350"
            :options="publicationsByDateOptions"
            :series="publicationsByDateSeries"
          ></apexcharts>
        </div>
      </div>

      <div class="myStatistic__row">
        <div class="myStatistic__diagram myStatistic__bigDiagram">
          <div class="myStatistic__header1">Число подписчиков</div>
          <apexcharts
            width="100%"
            height="350"
            type="area"
            :options="followersByChannelOptions"
            :series="followersByChannelSeries"
          ></apexcharts>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabControl from "@/components/shared/TabControl";
import { usePubStatisticStore } from "@/store/publication-count-statistic";
import { useFollowersHistoryStore } from "@/store/followes-history-statistic";
import { computed, onMounted, ref } from "vue";
import { useChannelStore } from "@/store/channel";
import { useViewStatisticStore } from "@/store/view-count-statistic";
import { usePublicationsHistoryStore } from "@/store/publications-history-statistic";
import DropButtonSelector from "@/components/shared/DropButtonSelector/DropButtonSelector";
import VueApexCharts from "vue3-apexcharts";
import { useMyChannelStore } from "@/store/my-channels";

export default {
  name: "ProfileMyStatisticPage",
  components: {
    DropButtonSelector,
    TabControl,
    apexcharts: VueApexCharts,
  },
  setup: function () {
    const publicationCountersStore = usePubStatisticStore();
    const followersStore = useFollowersHistoryStore();
    const publicationsStore = usePublicationsHistoryStore();
    const myChannelStore = useMyChannelStore();
    const mainChannelStore = useChannelStore();
    const viewStore = useViewStatisticStore();
    let timeoutHandle = null;

    const activePeriod = ref(1);

    const tabs = [
      { id: 1, name: "Сводка за день" },
      { id: 2, name: "Неделю" },
      { id: 3, name: "Месяц" },
    ];

    const followersByChannelSource = computed(() => {
      const channel = getChannel(myChannelStore.selectedChannel);
      if (!channel) {
        return [];
      }
      const res = [];
      const items = getFollowersByChannel(channel.id);
      const item = {
        category: channel.title,
        items: items,
      };
      console.log("newItem", item);
      res.push(item);

      console.log("result followers", res);
      return res;
    });

    const followersByChannelOptions = computed(() => {
      return {
        chart: {
          id: "vue-chart-followers-by-channels",
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#186AFE"],
        xaxis: {
          categories:
            followersByChannelSource.value?.[0]?.items?.map((item) => item.y) ||
            [],
        },
      };
    });

    const followersByChannelSeries = computed(() => {
      return [
        {
          name: "Подписчиков",
          data:
            followersByChannelSource.value?.[0]?.items?.map((item) => item.x) ||
            [],
        },
      ];
    });

    function getFollowersByChannel(channelId) {
      console.log("followersItems", followersStore.items);
      const found = followersStore.items?.find(
        (item) => item.channelId == channelId
      );
      return found?.history.map((item) => ({
        x: item.counter,
        y: item.actionDate,
      }));
    }
    function tabChangeHandler(tab) {
      activePeriod.value = tab.id;
      clearTimeout(timeoutHandle);
      timeoutHandle = setTimeout(() => loadData(), 1000);
    }
    const publicationsByDateSource = computed(() => {
      const channel = getChannel(myChannelStore.selectedChannel);
      if (!channel) {
        return [];
      }
      const res = [];
      const publishMap = new Map();
      console.log("publication  items", publicationsStore.items);
      publicationsStore.items
        ?.filter((item) => item.channelId == channel.id)
        .forEach((item) => {
          item.history?.forEach((action) => {
            publishMap.set(
              action.actionDate,
              (publishMap.get(action.actionDate) || 0) + action.counter
            );
          });
        });
      console.log("publishMap", publishMap);
      publishMap.forEach((value, key) => {
        res.push({ category: key, y: value, x: key });
      });

      const compareStrDate = (s1, s2) => {
        if (s1 < s2) {
          return 1;
        }
        if (s1 > s2) {
          return -1;
        }
        return 0;
      };

      res.sort((a, b) => compareStrDate(b.category, a.category));

      if (res.length > 5) {
        res.splice(0, res.length - 5);
      }

      console.log("res arr", res);
      return res;
    });

    const publicationsByDateOptions = computed(() => {
      return {
        chart: {
          id: "vue-chart-publications-by-date",
        },
        colors: ["#98AAE8"],
        dataLabels: {
          offsetY: -20,
          position: "top",
          style: {
            colors: ["#373D3F"],
          },
        },

        plotOptions: {
          bar: {
            borderRadius: 8,
            dataLabels: {
              position: "top",
            },
          },
        },

        xaxis: {
          categories:
            publicationsByDateSource.value?.map((item) => item.x) || [],
          position: "top",
        },
        yaxis: {
          show: false,
        },
      };
    });

    const publicationsByDateSeries = computed(() => {
      return [
        {
          name: "Публикаций",
          data: publicationsByDateSource.value?.map((item) => item.y) || [],
        },
      ];
    });

    const loading = computed(() => {
      return (
        publicationCountersStore.loading ||
        followersStore.loading ||
        viewStore.loading ||
        myChannelStore.loading ||
        publicationsStore.loading
      );
    });

    const publishedCount = computed(() => {
      const channel = getChannel(myChannelStore.selectedChannel);
      if (!channel) {
        return 0;
      }
      return publicationCountersStore.items
        ?.filter((item) => item.channelId == channel.id)
        .map((item) => item.count)
        .reduce((p, s) => +p + +s, 0);
    });

    const viewedCount = computed(() => {
      const channel = getChannel(myChannelStore.selectedChannel);
      if (!channel) {
        return 0;
      }
      console.log("viewedCount items", viewStore.items);
      return viewStore.items
        ?.filter((item) => item.channelId == channel.id)
        .map((item) => item.count)
        .reduce((p, s) => +p + +s, 0);
    });

    onMounted(async () => {
      await mainChannelStore.loadChannels(false);
      await loadData();
    });

    async function loadData() {
      await publicationCountersStore.clearData();
      await publicationsStore.clearData();
      await followersStore.clearData();
      await viewStore.clearData();

      const curDate = new Date();

      const startDate = new Date(curDate);
      const dayMilliseconds = 24 * 60 * 60 * 1000;
      let diff = dayMilliseconds;
      switch (activePeriod.value) {
        case 2 /* За неделю */:
          diff *= 8;
          break;
        case 3 /* За месяц */:
          diff *= 31;
          break;
      }
      curDate.setTime(curDate.getTime() - diff);
      startDate.setTime(curDate.getTime() - dayMilliseconds);

      const strCurDate = `${curDate.getFullYear()}-${
        curDate.getMonth() + 1
      }-${curDate.getDate()}`;
      const strStartDate = `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`;

      setTimeout(() => {
        publicationCountersStore.loadPubCounters(
          strCurDate,
          myChannelStore.channelsIds
        );
        followersStore.loadFollowersHistory(
          strStartDate,
          myChannelStore.channelsIds
        );
        viewStore.loadViewCounters(strCurDate, myChannelStore.channelsIds);
        publicationsStore.loadHistory(strCurDate, myChannelStore.channelsIds);
      }, 1000);
    }

    function getChannel(channelId) {
      return myChannelStore.items?.find((item) => item.id == channelId);
    }

    return {
      tabs,
      loading,
      publicationsByDateSource,
      publishedCount,
      viewedCount,
      followersByChannelSource,
      myChannelStore,
      activePeriod,
      tabChangeHandler,
      publicationsByDateOptions,
      publicationsByDateSeries,
      followersByChannelOptions,
      followersByChannelSeries,
      publicationCountersStore,
      followersStore,
      viewStore,
      publicationsStore,
    };
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/vars.scss";
.myStatistic {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 17px;
    background-color: #f6f7f9;
    padding-top: 17px;
  }
  &__counters {
    padding: 12px;
    display: grid;
    grid-template-columns: 120px auto;
    row-gap: 12px;
    column-gap: 12px;
    width: 350px;
    align-items: center;
  }
  &__counterValue {
    color: #186afe;
    font-size: 29px;
    padding-left: 20px;
    font-weight: 400;
    font-family: $font-roboto-regular;
    word-break: break-all;
  }
  &__countersInfo {
    color: #363636;
    font-family: $font-roboto-regular;
    font-size: 14px;
    font-weight: 400;
    word-break: break-all;
  }
  &__row {
    display: flex;
    gap: 18px;
    min-height: 253px;
    justify-content: space-between;
    padding: 0 1px;
  }
  &__countersPlate {
    display: flex;
    gap: 18px;
    min-height: 253px;
    flex-direction: column;
  }
  &__diagram {
    background: white;
    position: relative;
    width: 35%;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  &__wideDiagram {
    width: 67%;
  }
  &__halfDiagram {
    width: 50%;
  }
  &__bigDiagram {
    width: 100%;
  }
  &__header1 {
    font-family: $font-news-cycle-bold;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    margin: 20px 0 0 20px;
  }
}
</style>
