<template>
  <DropdownSlot v-model:is-open="isOpen">
    <template #trigger>
      <DropButton
        :is-open="isOpen"
        :value="modelValue"
        :options="options"
        :placeholder="placeholder"
        class="dropdown-btn-md"
      >
      </DropButton>
    </template>
    <SelectorList
      class="selector-default"
      :options="options"
      @optionClick="optionClickHandler"
    />
  </DropdownSlot>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";
import SelectorList from "@/components/shared/DropButtonSelector/SelectorList.vue";
import DropdownSlot from "@/components/shared/DropButtonSelector/DropdownSlot.vue";
import DropButton from "@/components/shared/DropButtonSelector/DropButton.vue";

defineProps({
  options: { type: Array, required: true },
  modelValue: { type: [Number || String], default: null, required: true },
  placeholder: { type: String, required: false, default: "Выбрать" },
});

const emits = defineEmits(["update:value"]);

function optionClickHandler(option) {
  emits("update:modelValue", option.id);
  openClose();
}

const isOpen = ref(false);

function openClose() {
  isOpen.value = !isOpen.value;
}
</script>

<style scoped lang="scss"></style>
