import axios from "axios";
import environment from "@/environment";
import { useAuthStore } from "@/store/auth";
import router from "@/router";

function isMyUrl(url: string): boolean {
  return url?.toLowerCase().indexOf(environment.appConfig.baseApiUrl) === 0;
}

export function axiosInit() {
  // Устанавливаю перехватчик для получения и добавления токена api
  console.log("init");
  const authStore = useAuthStore();
  axios.interceptors.request.use(async (config) => {
    //DEBUG:
    // console.log("intercept request", config.url);
    if (isMyUrl(config.url) && config.url.indexOf("/login") < 0) {
      // console.log("my url", config.url);
      config.headers.Authorization = "Bearer " + authStore.getAuthToken();
    }
    return config;
  });
  axios.interceptors.response.use(null, async (error) => {
    console.log("on error", error, error?.config);
    console.log("error url", error.config?.url);
    const retryCount = error?.config?.__retryCount || 0;
    if (retryCount > 0) {
      setTimeout(() => {
        console.log("Error check Access");
        router.push({ name: "login" }).then();
      }, 1);
      return Promise.reject(error);
    }
    if (
      retryCount < 1 &&
      error?.config &&
      isMyUrl(error.config.url) &&
      (error.response?.status === 403 || error.response?.status === 401)
    ) {
      await authStore.doRefreshToken();
      if (!authStore.error) {
        // удалось обновить токен
        error.config.__retryCount = retryCount + 1;
        return axios.request(error.config);
      }

      setTimeout(() => {
        console.log("Error check Access");
        router.push({ name: "login" }).then();
      }, 1);
      const code = error.response?.status;
      console.log("my", code);

      return Promise.reject(error);
    }
    return Promise.reject(error);
  });
}
