<template>
  <div
    v-if="showPopupWindow"
    class="avalanche-main-panel b-popup"
    :class="{ 'b-popup': !noTail, 'b-popup-no': !!noTail }"
    @click.prevent.stop="showPopupWindow = false"
  >
    <div
      :class="currentClass"
      :style="{
        left: left,
        top: top,
        right: right,
        bottom: bottom,
        width: width,
      }"
    >
      <slot> </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopupWindow",
  props: [
    "modelValue",
    "innerClass",
    "noTail",
    "left",
    "top",
    "right",
    "bottom",
    "width",
  ],
  emits: ["update:modelValue"],
  computed: {
    showPopupWindow: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    currentClass() {
      let res = this.innerClass || "";
      res += " ";
      if (!this.noTail) res += "b-popup-content";
      else res += "b-popup-content-no";
      return res;
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/vars";

.b-popup,
.b-popup-no {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 300000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  background: rgba(0, 0, 0, 0);
  opacity: 1;
}

.b-popup .b-popup-content,
.b-popup-no .b-popup-content_no {
  position: absolute;
  width: 100px;
  right: 11px;
  top: 60px;
  background-color: $item-background-color;
  border-radius: 10px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
  pointer-events: auto;
  padding: 8px 10px;
  margin: 5px 0;
  z-index: 300001;
}

.b-popup-content:before {
  content: "";
  width: 0;
  height: 0;
  top: -6px;
  right: 1px;
  position: absolute;
  background: transparent;
  border-bottom: 12px solid $item-background-color;
  border-left: 22px solid transparent;
  border-right: 22px solid transparent;
}
</style>
