<template>
  <button ref="button" :class="{ active: isOpen }">
    <slot name="button-icon" />
    <span class="optionView" v-if="optionByValue?.id">
      <img
        class="optionImage"
        :src="optionByValue.url"
        v-if="optionByValue.url"
      />
      <span>
        {{
          optionByValue?.title ||
          optionByValue?.label ||
          optionByValue?.name ||
          optionByValue?.text
        }}
      </span>
    </span>
    <span v-else>{{ placeholder }}</span>
    <img
      class="button-arrow"
      :class="{ rotated: isOpen }"
      :src="require('@/assets/icons/arrow.svg')"
      alt="arrow"
    />
  </button>
</template>

<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
  value: { type: [Number, null], default: null, required: true },
  options: { type: Array, required: true },
  placeholder: { type: String, required: false, default: "Выбрать" },
  isOpen: { type: Boolean, required: true },
});
const optionByValue = computed(() => {
  return (props.options || []).find((item) => props.value === item.id);
});
</script>

<style scoped lang="scss">
@mixin drp-btn {
  color: #212327;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: solid 1px #dedede;
  gap: 4px;
  border-radius: 10px;
  background-color: white;
  width: 100%;
  justify-content: space-between;
  line-height: 26px;

  .button-arrow {
    transition: transform 0.2s ease-in-out;
    height: 7px;
  }

  .rotated {
    transform: rotate(180deg);
  }

  div {
    padding: 0 8px;
  }
}
.dropdown-btn-md {
  @include drp-btn;
  padding: 8px;
}

.dropdown-btn-sm {
  @include drp-btn;
  padding: 4px 8px;
}
.optionView {
  width: 90%;
  display: flex;
  gap: 5px;
  align-items: center;
  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    display: block;
  }
}
.optionImage {
  width: 20px;
  height: 20px;
}
</style>
