import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-46fdb218"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menuDialogList" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menu_dialog_items_icon = _resolveComponent("menu-dialog-items-icon")!
  const _component_custom_right_dialog = _resolveComponent("custom-right-dialog")!

  return ($setup.showDialog)
    ? (_openBlock(), _createBlock(_component_custom_right_dialog, {
        key: 0,
        width: "250px",
        "background-cancel": true,
        "is-right": true,
        onCancelClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.showDialog = false)),
        onOkClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.showDialog = false)),
        "border-radius": "0",
        "use-buttons": false,
        "hide-background": true,
        "hide-border": true
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.menuItems, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["menuDialogList__item", { menuDialogList__active: _ctx.$route.name === item.routeName }]),
                key: item.id,
                onClick: ($event: any) => ($setup.itemClickHandler(item))
              }, [
                _createVNode(_component_menu_dialog_items_icon, {
                  "icon-name": item.routeName
                }, null, 8, ["icon-name"]),
                _createTextVNode(" " + _toDisplayString(item.name), 1)
              ], 10, _hoisted_2))
            }), 128))
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}