<template>
  <div class="login-view">
    <login-box @login="loginHandler" />
  </div>
</template>

<script lang="ts">
import { ref } from "vue";
import { useAuthStore } from "@/store/auth";
import { createToaster } from "@meforma/vue-toaster";
import LoginBox from "@/components/LoginView/LoginBox.vue";
import { useRouter } from "vue-router";

export default {
  name: "LoginView",
  components: { LoginBox },
  setup() {
    const router = useRouter();
    const toaster = createToaster();
    const authStore = useAuthStore();
    const username = ref(null);
    const password = ref(null);

    async function loginHandler() {
      console.log("login", authStore.username);
      await authStore.doLogin(authStore.username, authStore.password);
      if (authStore.error) {
        toaster.error(authStore.error);
        return;
      }
      console.log("goto /");
      router.push("/").then();
    }

    return { username, password, loginHandler };
  },
};
</script>

<style lang="scss" scoped>
.login-view {
  background: center center white url("@/assets/img/login-bg.png");
  position: fixed;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
</style>
