import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { useAuthStore } from "@/store/auth";
import LoginView from "@/components/LoginView.vue";
import { personalAccountRoutes } from "./personal-account";
import ProfileStatisticPage from "@/components/Analytics/ProfileStatisticPage.vue";
import { analyticsRoutes } from "@/router/analytics";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/publish",
  },
  {
    path: "/main",
    name: "MyChannels",
    component: () => import("../components/MainPageView.vue"),
  },
  {
    path: "/publish/:channelId?",
    name: "Publish",
    props: true,
    component: () => import("../components/PublishPageView.vue"),
  },
  {
    path: "/lk",
    name: "PersonalAccount",
    component: () => import("../components/PersonalAccountView.vue"),
    children: personalAccountRoutes,
  },
  {
    path: "/analytics",
    name: "Analytics",
    component: ProfileStatisticPage,
    children: analyticsRoutes,
  },
  {
    path: "/login",
    name: "login",
    meta: {
      isPublic: true,
    },
    component: LoginView,
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../components/SettingsView.vue"),
  },
  {
    path: "/services",
    name: "Services",
    component: () => import("../components/ServicesView.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  console.log("[info] ROUTER BEFORE EACH", authStore.getAuthToken());
  if (!to.meta.isPublic && !authStore.getAuthToken()) {
    console.log("goto login", authStore.getAuthToken());
    await router.push({ name: "login" });
  }
  next();
});
