<template>
  <main-nav></main-nav>
  <router-view />
</template>

<script lang="ts">
import MainNav from "@/components/shared/MainNav.vue";
export default {
  name: "SiteLayout",
  components: { MainNav },
};
</script>

<style scoped></style>
