import { defineStore } from "pinia";
import useCrudStore from "@/store/composables/crud";
import environment from "@/environment";
import useCancelTokenStore from "@/store/composables/cancel-token";
import axios from "axios";
import { FollowerCounterRecord } from "@/model/followes-history";
import { PublicationCounters } from "@/model/publications-history";

export const usePublicationsHistoryStore = defineStore(
  "publications-history-statistic",
  () => {
    const crud = useCrudStore(
      environment.appConfig.baseApiUrl + "/statistic/publications/history"
    );
    const cancelToken = useCancelTokenStore();

    async function loadHistory(startDate: string, channelIds: string[] = null) {
      console.log("loadHistory", startDate, channelIds);
      cancelToken.acquireToken();
      crud.startLoading();
      const params = {
        start_date: startDate,
      };

      try {
        const data = (
          await axios.get(crud.endpoint.value, {
            cancelToken: cancelToken.cancelToken.value,
            params,
          })
        )?.data;

        const arr: PublicationCounters[] = [];
        for (const channelId in data?.items) {
          // Отбираю только для заданных каналов
          if (channelIds?.length && !channelIds.find((id) => id == channelId)) {
            continue;
          }
          const history: FollowerCounterRecord[] = [];
          const historyObject = data?.items[channelId];
          let counter = 0;
          let priorValue = -1;
          for (const dateKey in historyObject) {
            const pubCount = +historyObject[dateKey];
            // Если -1, то ставлю 0 так как прирост неизвестен
            //
            const progressCount = priorValue < 0 ? 0 : pubCount - priorValue;

            counter += progressCount;

            history.push({
              actionDate: dateKey,
              counter: pubCount,
              progress: progressCount,
            });
            priorValue = pubCount;
          }
          arr.push({ channelId, counter, history });
        }
        crud.addItems(arr);
        crud.clearLoading();
      } catch (e) {
        crud.setError(e);
      }
    }

    return {
      ...crud,
      loadHistory,
    };
  }
);
