<template>
  <div class="position-relative">
    <div ref="button" role="button" @click="openClose">
      <slot name="trigger" />
    </div>

    <div v-if="isOpen" ref="content" class="dropdown-content">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const button = ref(null);
const content = ref(null);

const props = defineProps({
  isOpen: { required: true, default: false },
});

const emits = defineEmits(["update:isOpen"]);

function openClose() {
  function onOutsideClick(e) {
    if (
      props.isOpen &&
      !button.value?.contains(e.target) &&
      !content.value?.contains(e.target)
    ) {
      emits("update:isOpen", !props.isOpen);
      document.removeEventListener("click", onOutsideClick);
    }
  }

  if (!props.isOpen) document.addEventListener("click", onOutsideClick);
  else document.removeEventListener("click", onOutsideClick);
  emits("update:isOpen", !props.isOpen);
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/vars.scss";

.dropdown-content {
  position: absolute;
  background-color: white;
  width: 100%;
  white-space: break-spaces;
  z-index: 10;

  margin-top: 4px;
  display: block;
  box-shadow: $dropdown-content-box-shadow;
}

.drp-content-left {
  .dropdown-content {
    right: 0;
  }
}
</style>
