export function isDefined(v) {
  return v !== null && v !== undefined && v !== "";
}
export function formatDate(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
export function firstDayOfWeek(dateObject, firstDayOfWeekIndex) {
  const dayOfWeek = dateObject.getDay(),
    firstDayOfWeek = new Date(dateObject),
    diff =
      dayOfWeek >= firstDayOfWeekIndex
        ? dayOfWeek - firstDayOfWeekIndex
        : 6 - dayOfWeek;

  firstDayOfWeek.setDate(dateObject.getDate() - diff);
  firstDayOfWeek.setHours(0, 0, 0, 0);

  return firstDayOfWeek;
}

export function getFilterInterval(days) {
  const from = new Date();
  from.setDate(from.getDate() - days);
  return {
    dateFrom: formatDate(from),
    dateTo: formatDate(new Date()),
  };
}

export function makeSpacesNormal(str) {
  return str.replace(/(?=\s)[^\r\n\t]/g, " ");
}

export function parseLocationPart(prefix, argIndex) {
  const location = document.location || window.location;
  const str = location.pathname;
  prefix += "/";
  console.log(prefix, str);
  const idx = str.indexOf(prefix);
  console.log(idx, prefix, str);
  if (idx < 0) return null;
  const args = str.substr(idx + prefix.length).split("/");

  console.log("args", args);
  if (args && argIndex < args.length) return args[argIndex];
  return null;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function shrinkStringRight(s, l) {
  if (s.length <= l) {
    return s;
  }
  return s.substring(0, l - 3) + "...";
}

export function upperFirst(str) {
  return str[0].toUpperCase() + str.slice(1);
}

export function elementVisible(target) {
  // Все позиции элемента
  const targetPosition = {
      top: window.pageYOffset + target.getBoundingClientRect().top,
      left: window.pageXOffset + target.getBoundingClientRect().left,
      right: window.pageXOffset + target.getBoundingClientRect().right,
      bottom: window.pageYOffset + target.getBoundingClientRect().bottom,
    },
    // Получаем позиции окна
    windowPosition = {
      top: window.pageYOffset,
      left: window.pageXOffset,
      right: window.pageXOffset + document.documentElement.clientWidth,
      bottom: window.pageYOffset + document.documentElement.clientHeight,
    };

  if (
    targetPosition.bottom > windowPosition.top && // Если позиция нижней части элемента больше позиции верхней чайти окна, то элемент виден сверху
    targetPosition.top < windowPosition.bottom && // Если позиция верхней части элемента меньше позиции нижней чайти окна, то элемент виден снизу
    targetPosition.right > windowPosition.left && // Если позиция правой стороны элемента больше позиции левой части окна, то элемент виден слева
    targetPosition.left < windowPosition.right
  ) {
    // Если позиция левой стороны элемента меньше позиции правой чайти окна, то элемент виден справа
    // Если элемент полностью видно, то запускаем следующий код
    //   console.clear();
    return true;
  }
  return false;
}

export function insertSubstring(str, substring, index) {
  if (index < 0 || index > str.length) {
    throw new Error("Index out of range");
  }
  return str.slice(0, index) + substring + str.slice(index);
}
// Функция для определения позиции курсора в тексте
export function getCursorPositionInText(text, selectionStart) {
  let textPosition = 0;
  for (let i = 0; i < selectionStart; i++) {
    textPosition += text[i].length;
  }
  return textPosition;
}

// Функция для определения позиции курсора в HTML-коде
export function getCursorPositionInHtml(html, selectionStart) {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;

  let textPosition = 0;
  let currentNode = tempDiv.firstChild;

  while (currentNode && textPosition < selectionStart) {
    if (currentNode.nodeType === Node.TEXT_NODE) {
      textPosition += currentNode.textContent.length;
    }
    currentNode = currentNode.nextSibling;
  }

  return textPosition;
}
// Функция для вставки строки в HTML-код на определённой позиции
export function insertStringAtPosition(html, position, string) {
  return html.slice(0, position) + string + html.slice(position);
}
export function restoreSelectionBaseOffset(baseOffset) {
  const selection = window.getSelection();
  const range = selection.getRangeAt(0);

  try {
    range.setStart(range.startContainer, baseOffset);
    selection.removeAllRanges();
    selection.addRange(range);
  } catch (error) {
    if (error.name === "IndexSizeError") {
      console.error(
        "Error restoring selection: There is no child at offset",
        baseOffset
      );
      // Handle the error, e.g., set the selection to the beginning or end of the text
      range.setStart(range.startContainer, 0);
      selection.removeAllRanges();
      selection.addRange(range);
    } else {
      throw error;
    }
  }
}

export function preserveCaretPosition(element, callback) {
  const selection = window.getSelection();
  const range = selection.getRangeAt(0);
  const startOffset = range.startOffset;
  const oldLen = element.textContent.length;
  callback(); // Выполняем действие, которое может изменить textContent
  const newLen = element.textContent.length;
  // Восстанавливаем позицию курсора
  selection.removeAllRanges();
  const newRange = document.createRange();
  newRange.setStart(element.childNodes[0], startOffset + newLen - oldLen);
  newRange.collapse(true);
  selection.addRange(newRange);
}
