<template>
  <popup-window v-model="showPopup" inner-class="main-panel-user-adv-menu">
    <div class="menu-container">
      <span class="btn-func btn-exit" @click.prevent="signOutHandler"></span>
      <span
        class="btn-func btn-settings"
        @click.prevent="settingsHandler"
      ></span>
      <img
        class="user-image"
        style="object-fit: contain"
        src="data:image/svg+xml,%3Csvg width='34' height='34' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 .333C7.8.333.333 7.8.333 17 .333 26.2 7.8 33.667 17 33.667c9.2 0 16.667-7.467 16.667-16.667C33.667 7.8 26.2.333 17 .333ZM8.783 27.467C9.5 25.967 13.867 24.5 17 24.5c3.133 0 7.517 1.467 8.217 2.967A13.155 13.155 0 0 1 17 30.333c-3.1 0-5.95-1.066-8.217-2.866ZM27.6 25.05c-2.383-2.9-8.167-3.883-10.6-3.883s-8.217.983-10.6 3.883A13.25 13.25 0 0 1 3.667 17C3.667 9.65 9.65 3.667 17 3.667c7.35 0 13.333 5.983 13.333 13.333a13.25 13.25 0 0 1-2.733 8.05ZM17 7a5.818 5.818 0 0 0-5.833 5.833c0 3.234 2.6 5.834 5.833 5.834s5.833-2.6 5.833-5.834C22.833 9.6 20.233 7 17 7Zm0 8.333a2.497 2.497 0 0 1-2.5-2.5c0-1.383 1.117-2.5 2.5-2.5s2.5 1.117 2.5 2.5c0 1.384-1.117 2.5-2.5 2.5Z' fill='silver'/%3E%3C/svg%3E"
      />
      <div class="user-name">
        <span
          style="overflow: hidden; text-overflow: ellipsis; display: inherit"
          class="text-nowrap"
        >
          {{ authStore.username }}
          <!-- b v-if="authStore.userData.organization">({{ authStore.userData.organization }})</b -->
        </span>
      </div>

      <hr class="top-separator" />
      <div class="admin-panel-link" @click.prevent="signOutHandler">Выход</div>
    </div>
  </popup-window>
</template>

<script>
import PopupWindow from "@/components/shared/PopupWindow";
import { useAuthStore } from "@/store/auth";

export default {
  name: "UserAdvMenu",
  components: { PopupWindow },
  props: ["modelValue"],
  setup() {
    const authStore = useAuthStore();
    return {
      authStore,
    };
  },

  methods: {
    adminPanelHandler() {
      window.location = "http://zyx.avl.team";
    },
    signOutHandler() {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      this.$router.push("/login").then();
    },
    signInHandler() {
      window.oidc.signIn();
    },
    settingsHandler() {
      console.log("under construction");
    },
  },
  computed: {
    showPopup: {
      get() {
        console.log("ShowUser menu");
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style lang="scss">
.avalanche-main-panel {
  .main-panel-user-adv-menu {
    width: 256px !important;
    height: 200px !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.41px;
    right: 80px !important;
  }
}
</style>

<style lang="scss" scoped>
@import "src/assets/styles/vars";

.btn-func {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 5px;
  display: block;
}

.btn-settings {
  right: 35px;
  background: center no-repeat
    url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)' stroke='%23CCC' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M10 12.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z'/%3E%3Cpath d='M16.167 12.5a1.375 1.375 0 0 0 .275 1.517l.05.05a1.666 1.666 0 0 1-1.18 2.847 1.667 1.667 0 0 1-1.179-.489l-.05-.05a1.375 1.375 0 0 0-1.516-.275 1.375 1.375 0 0 0-.834 1.258v.142a1.667 1.667 0 0 1-3.333 0v-.075a1.375 1.375 0 0 0-.9-1.258 1.375 1.375 0 0 0-1.517.275l-.05.05a1.667 1.667 0 1 1-2.358-2.359l.05-.05a1.375 1.375 0 0 0 .275-1.516 1.376 1.376 0 0 0-1.258-.834H2.5a1.667 1.667 0 0 1 0-3.333h.075a1.375 1.375 0 0 0 1.258-.9 1.375 1.375 0 0 0-.275-1.517l-.05-.05a1.667 1.667 0 1 1 2.359-2.358l.05.05a1.375 1.375 0 0 0 1.516.275H7.5a1.375 1.375 0 0 0 .833-1.258V2.5a1.667 1.667 0 0 1 3.334 0v.075a1.376 1.376 0 0 0 .833 1.258 1.375 1.375 0 0 0 1.517-.275l.05-.05a1.666 1.666 0 0 1 2.72 1.818 1.666 1.666 0 0 1-.362.54l-.05.05a1.375 1.375 0 0 0-.275 1.517V7.5a1.375 1.375 0 0 0 1.258.833h.142a1.667 1.667 0 0 1 0 3.334h-.075a1.375 1.375 0 0 0-1.258.833v0Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h20v20H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}
.btn-exit {
  right: 5px;
  background: center no-repeat
    url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 17.5H4.167A1.667 1.667 0 0 1 2.5 15.833V4.167A1.667 1.667 0 0 1 4.167 2.5H7.5M13.333 14.167 17.5 10l-4.167-4.167M17.5 10h-10' stroke='%23CCC' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.user-image {
  margin: 15px 0;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  padding: 0;
}

.menu-container {
  position: relative;
  text-align: center;
  font-family: $main-font-family;
  font-size: 16px;
  cursor: default;
  div,
  span {
    color: $user-name-font-color;

    :hover,
    :focus,
    :active {
      color: $user-name-font-color;
    }
  }
  hr {
    border: none;
    border-top: $menu-separator-color 1px solid;
    margin: 0;
    padding-bottom: 0;
  }
  .project-menu-item {
    font-size: 14px;
  }
  .adv-menu-item {
    font-size: 14px;
  }

  .project-help-caption {
    font-size: 8px;
    color: $menu-separator-color !important;
    padding-top: 2px;
    margin-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .project-name {
    color: #1158c2;
    padding-bottom: 0;
    margin-bottom: 10px;
    font-size: 14px;
    cursor: pointer;
  }
  .user-name {
    padding-bottom: 15px;
  }

  .admin-panel-link {
    font-size: 14px;
    cursor: pointer;
    margin-top: 18px;
  }
}
</style>
