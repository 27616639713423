import { defineStore } from "pinia";
import environment from "@/environment";
import axios from "axios";
import useCrudStore from "@/store/composables/crud";
import { ref } from "vue";

export const useAuthStore = defineStore("auth", () => {
  const crud = useCrudStore(environment.appConfig.baseAuthApiUrl);
  const username = ref("");
  const password = ref("");

  function getAuthToken(): string {
    return localStorage.getItem("token");
  }
  function getRefreshToken(): string {
    return localStorage.getItem("refresh-token");
  }
  function sendToWorker(token: string) {
    if (!environment.appConfig.serviceWorkerRegistration?.active) {
      return;
    }
    console.log(
      "sw reg",
      environment.appConfig.serviceWorkerRegistration?.active
    );
    environment.appConfig.serviceWorkerRegistration.active.postMessage(token);
  }
  function setAuthToken(token: string) {
    localStorage.setItem("token", token);
    sendToWorker(token);
  }
  function setRefreshToken(token: string) {
    localStorage.setItem("refresh-token", token);
  }

  async function doLogin(username: string, password: string) {
    const url = crud.endpoint.value + "/login";
    const data = {
      username,
      password,
    };
    try {
      crud.loading.value = true;
      const response = await axios.post(url, JSON.stringify(data, null, 4), {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response?.data?.access_token) {
        throw "Unable to get token";
      }
      setAuthToken(response.data.access_token);
      setRefreshToken(response.data.refresh_token);
      crud.clearLoading();
    } catch (e) {
      crud.setError(e);
    }
  }
  async function doRefreshToken() {
    const url = crud.endpoint.value + "/refresh";
    const data = {
      refresh_token: getRefreshToken(),
    };
    try {
      crud.loading.value = true;
      const response = await axios.post(url, JSON.stringify(data, null, 4), {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response?.data?.access_token) {
        throw "Unable to get token";
      }
      setAuthToken(response.data.access_token);
      setRefreshToken(response.data.refresh_token);
      crud.clearLoading();
    } catch (e) {
      crud.setError(e);
    }
  }

  return {
    ...crud,
    username,
    password,
    getAuthToken,
    getRefreshToken,
    setAuthToken,
    setRefreshToken,
    doLogin,
    doRefreshToken,
    sendToWorker,
  };
});
