<template>
  <ul>
    <li
      v-for="option in options"
      :key="option.id"
      :class="{ disabled: option.disabled }"
      @click="optionClickHandler(option)"
    >
      <span>
        <img :src="option.url" v-if="option.url" />
        {{ option.title || option.label || option.name || option.text }}</span
      >
    </li>
  </ul>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

defineProps({
  options: { type: Array, required: true },
});

const emits = defineEmits(["optionClick"]);

function optionClickHandler(option) {
  emits("optionClick", option);
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/classes.scss";

.selector-default {
  border: solid 1px #dedede;
  border-radius: 10px;
  width: 100%;
  white-space: break-spaces;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;

  li {
    display: flex;
    list-style-type: none;
    position: relative;
    cursor: pointer;
    text-align: start;
    margin: 8px;
    padding: 8px 16px;
    border-radius: 10px;
    font-size: 16px;
    span {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
  li:hover {
    background-color: #e0f0ff;
  }
  .disabled {
    cursor: not-allowed;
    color: #ccc;
  }
  img {
    height: 24px;
    width: 24px;
    border-radius: 100%;
  }
}
</style>
