<template>
  <div class="tabControl">
    <span
      v-for="tab in tabs"
      :key="tab.id"
      :class="{
        tabControl__tab_active: activeTab === tab.id,
        tabControl__noUnderline: noUnderline,
      }"
      @click="$emit('tabClick', tab)"
    >
      {{ tab.name }}
    </span>
  </div>
</template>

<script lang="ts">
import { computed } from "vue";

export default {
  name: "TabControl",
  props: {
    tabs: { type: Array, required: true },
    activeTab: { type: Number, required: false },
    noUnderline: { type: Boolean, required: false, default: false },
  },
  emits: ["tabClick"],
  setup() {
    const selectedId = computed(() => {
      return null;
    });
    return { selectedId };
  },
};
</script>

<style scoped lang="scss">
.tabControl {
  display: flex;
  color: #a7a7a7;
  gap: 20px;
  padding: 8px 12px 12px;
  span {
    cursor: pointer;
    padding-bottom: 12px;
  }
  &__tab_active {
    border-bottom: 3px solid #186afe;
    color: #186afe;
  }
  &__noUnderline {
    border-bottom: none !important;
  }
}
</style>
