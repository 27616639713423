import { defineStore } from "pinia";
import { useChannelStore } from "@/store/channel";
import { computed } from "vue";
import useChannelFilter from "@/store/composables/channel-filter";

export const useMyChannelStore = defineStore("my-channels", () => {
  const channelStore = useChannelStore();

  const items = computed(() => {
    return channelStore.items.filter(
      (item) => item.rules.can_write && !item.rules.can_read
    );
  });
  const channelFilter = useChannelFilter(items);
  return {
    items,
    ...channelFilter,
    loading: channelStore.loading,
  };
});
