<template>
  <div class="myStatistic">
    <tab-control
      :active-tab="activePeriod"
      :tabs="tabs"
      @tabClick="tabChangeHandler"
    ></tab-control>
    <div class="myStatistic__container">
      <div class="myStatistic__row">
        <span class="myStatistic__counters">
          <div class="myStatistic__counterValue">
            {{ channelCount }}
          </div>
          <div class="myStatistic__counterInfo">Каналов просмотрено</div>
          <span class="myStatistic__counterValue"> {{ publishedCount }} </span>
          <span class="myStatistic__counterInfo">Новостей прочитано</span>
        </span>

        <div class="myStatistic__diagram myStatistic__bigDiagram">
          <div class="myStatistic__header1">Топ публикаций</div>
          <!-- pre>{{ JSON.stringify(topPublicationsSource) }}</pre -->
          <apexcharts
            width="100%"
            height="350"
            type="bar"
            :options="topPublicationsOptions"
            :series="topPublicationsSeries"
          ></apexcharts>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabControl from "@/components/shared/TabControl";
import { usePubStatisticStore } from "@/store/publication-count-statistic";
import { computed, onMounted, ref } from "vue";
import { useChannelStore } from "@/store/channel";
import { shrinkStringRight } from "@/tools/tools";
import VueApexCharts from "vue3-apexcharts";

export default {
  name: "ProfileSourcesStatisticPage",
  components: {
    TabControl,
    apexcharts: VueApexCharts,
  },
  setup() {
    const publicationCountersStore = usePubStatisticStore();
    const channelStore = useChannelStore();
    const activePeriod = ref(1);
    let timeoutHandle = null;
    const tabs = [
      { id: 1, name: "Сводка за день" },
      { id: 2, name: "Неделю" },
      { id: 3, name: "Месяц" },
    ];

    const topPublicationsSource = computed(() => {
      const arr = publicationCountersStore.items
        ?.slice()
        .sort((item1, item2) => item2.count - item1.count);
      if (!arr) {
        return [];
      }
      arr.splice(5);
      arr.sort((item1, item2) => item1.count - item2.count);
      const res = arr.map((item) => ({
        category: getChannelName(item.channelId),
        y: item.count,
        x: getChannelName(item.channelId),
      }));
      console.log("top publication", res);
      return res;
    });

    const topPublicationsOptions = computed(() => {
      return {
        chart: {
          id: "vue-chart-top-publications",
        },
        colors: ["#BCB8FF"],
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        xaxis: {
          categories: topPublicationsSource.value?.map((item) => item.x) || [],
        },
      };
    });
    const topPublicationsSeries = computed(() => {
      return [
        {
          name: "Публикаций",
          data: topPublicationsSource.value?.map((item) => item.y) || [],
        },
      ];
    });

    const loading = computed(() => {
      return publicationCountersStore.loading || channelStore.loading;
    });

    const publishedCount = computed(() => {
      return publicationCountersStore.items
        ?.map((item) => item.count)
        .reduce((p, s) => +p + +s, 0);
    });
    const channelCount = computed(() => {
      return channelStore.sourceChannels?.length;
    });

    onMounted(async () => {
      await channelStore.loadChannels(false);
      await loadData();
    });

    async function loadData() {
      await publicationCountersStore.clearData();

      const curDate = new Date();

      const startDate = new Date(curDate);
      const dayMilliseconds = 24 * 60 * 60 * 1000;
      let diff = dayMilliseconds;
      switch (activePeriod.value) {
        case 2 /* За неделю */:
          diff *= 8;
          break;
        case 3 /* За месяц */:
          diff *= 31;
          break;
      }
      curDate.setTime(curDate.getTime() - diff);
      startDate.setTime(curDate.getTime() - dayMilliseconds);

      const strCurDate = `${curDate.getFullYear()}-${
        curDate.getMonth() + 1
      }-${curDate.getDate()}`;

      setTimeout(() => {
        publicationCountersStore.loadPubCounters(strCurDate, null);
      }, 1000);
    }
    function tabChangeHandler(tab) {
      activePeriod.value = tab.id;
      clearTimeout(timeoutHandle);
      timeoutHandle = setTimeout(() => loadData(), 1000);
    }

    function getChannelName(channelId) {
      const found = getChannel(channelId);
      if (!found) {
        return channelId;
      }
      return shrinkStringRight(found.title, 25);
    }
    function getChannel(channelId) {
      return channelStore.items?.find((item) => item.id == channelId);
    }

    return {
      tabs,
      topPublicationsSource,
      loading,
      publishedCount,
      channelCount,
      activePeriod,
      topPublicationsOptions,
      topPublicationsSeries,
      tabChangeHandler,
    };
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/vars.scss";
.myStatistic {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 17px;
    padding-top: 17px;
  }
  &__counters {
    padding: 12px;
    display: grid;
    grid-template-columns: 120px auto;
    row-gap: 12px;
    column-gap: 12px;
    width: 350px;
    align-items: center;
  }
  &__counterValue {
    color: #186afe;
    font-size: 29px;
    font-weight: 400;
    font-family: $font-roboto-regular;
    word-break: break-all;
    padding-left: 20px;
  }
  &__countersInfo {
    color: #363636;
    font-family: $font-roboto-regular;
    font-size: 14px;
    font-weight: 400;
    word-break: break-all;
  }
  &__row {
    display: flex;
    gap: 18px;
    min-height: 253px;
  }
  &__diagram {
    background: white;
    position: relative;
    width: 35%;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  &__wideDiagram {
    width: 67%;
  }
  &__halfDiagram {
    width: 50%;
  }
  &__bigDiagram {
    width: 100%;
  }
  &__header1 {
    font-family: $font-news-cycle-bold;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    margin: 20px 0 0 20px;
  }
}
</style>
