import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7dcf1c59"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabControl" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tabs, (tab) => {
      return (_openBlock(), _createElementBlock("span", {
        key: tab.id,
        class: _normalizeClass({
        tabControl__tab_active: $props.activeTab === tab.id,
        tabControl__noUnderline: $props.noUnderline,
      }),
        onClick: ($event: any) => (_ctx.$emit('tabClick', tab))
      }, _toDisplayString(tab.name), 11, _hoisted_2))
    }), 128))
  ]))
}