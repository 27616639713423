import { RouteRecordRaw } from "vue-router";
import ProfileMyStatisticPage from "@/components/Analytics/ProfileMyStatisticPage.vue";
import ProfileSourcesStatisticPage from "@/components/Analytics/ProfileSourcesStatisticPage.vue";

export const analyticsRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: { name: "PersonalAccountMyStatistic" },
  },
  {
    path: "my",
    name: "PersonalAccountMyStatistic",
    component: ProfileMyStatisticPage,
  },
  {
    path: "sources",
    name: "PersonalAccountSourcesStatistic",
    component: ProfileSourcesStatisticPage,
  },
];
