<template>
  <transition>
    <div>
      <div
        class="modal-background"
        :class="{ 'modal-background__hide': hideBackground }"
        :style="{ zIndex: zIndex }"
        @click="backgroundClickHandler"
      ></div>
      <div
        :class="
          'modal-dlg' +
          (isRight ? ' vue-cs-right-modal' : '') +
          (modalDlgClass || '') +
          (hideBorder ? ' modal-dlg__hide-border' : '')
        "
        ref="outerDlg"
        tabindex="0"
        :style="{
          width: width,
          height: height,
          minWidth: minWidth,
          maxWidth: maxWidth,
          zIndex: zIndex + 2,
          borderRadius: borderRadius,
        }"
        @keypress.enter="enterPress"
        @keydown.esc="escPress"
      >
        <div class="modal-body">
          <a
            class="btn-modal-close"
            href="#"
            v-if="useCloseButton && !isRight"
            @click.prevent="$emit('cancel-click')"
          >
          </a>
          <div class="dialog-header" v-if="header">{{ header }}</div>
          <slot> </slot>
        </div>

        <div v-if="useButtons" :class="'modal-footer ' + footerClass">
          <div class="modal-button-panel">
            <simple-button
              v-if="useAdd"
              ref="btAdd"
              :class="buttonClass"
              @click.prevent="$emit('add-click')"
              :disabled="addDisabled"
            >
              {{ addText }}
            </simple-button>

            <simple-button
              v-if="useCancel"
              ref="btCancel"
              :class="buttonClass"
              @click.prevent="$emit('cancel-click')"
              :disabled="cancelDisabled"
            >
              {{ cancelText }}
            </simple-button>

            <simple-button
              v-if="useOk"
              ref="btOk"
              :class="buttonClass"
              @click.prevent="$emit('ok-click')"
              :disabled="okDisabled"
            >
              {{ okText }}
            </simple-button>

            <slot name="buttons"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import SimpleButton from "@/components/shared/SimpleButton";
export default {
  name: "CustomRightDialog",
  components: { SimpleButton },
  props: {
    width: {
      type: String,
      default: "500px",
    },
    height: {
      type: String,
    },
    top: {
      type: String,
      default: "200px",
    },
    minWidth: {
      type: String,
    },
    maxWidth: {
      type: String,
    },
    useButtons: {
      type: Boolean,
      default: true,
    },
    useOk: {
      type: Boolean,
      default: true,
    },
    useCancel: {
      type: Boolean,
      default: true,
    },
    useAdd: {
      type: Boolean,
      default: false,
    },
    okText: {
      type: String,
      default: "OK",
    },
    cancelText: {
      type: String,
      default: "Отмена",
    },
    addText: {
      type: String,
      default: "Additional",
    },
    enterOk: {
      type: Boolean,
      default: false,
    },
    enterCancel: {
      type: Boolean,
      default: false,
    },
    escCancel: {
      type: Boolean,
      default: false,
    },
    okDisabled: {
      type: Boolean,
      default: false,
    },
    cancelDisabled: {
      type: Boolean,
      default: false,
    },
    addDisabled: {
      type: Boolean,
      default: false,
    },
    buttonClass: {
      type: String,
      default: "",
    },
    footerClass: {
      type: String,
      default: "",
    },
    modalDlgClass: {
      type: String,
      default: "",
    },
    useCloseButton: {
      type: Boolean,
      default: true,
    },
    zIndex: {
      type: Number,
      default: 1000,
    },
    backgroundCancel: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
    },
    isRight: {
      type: Boolean,
      default: false,
    },
    borderRadius: {
      type: String,
      default: "20px",
    },
    hideBackground: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["ok-click", "cancel-click"],
  methods: {
    enterPress() {
      if (this.enterOk) {
        this.$emit("ok-click");
        return;
      }
      if (this.enterCancel) {
        this.$emit("cancel-click");
        return;
      }
    },
    escPress() {
      if (this.escCancel) {
        this.$emit("cancel-click");
        return;
      }
    },
  },
  setup(props, { emit }) {
    function backgroundClickHandler() {
      if (!props.backgroundCancel) {
        return;
      }
      emit("cancel-click");
    }

    return { backgroundClickHandler };
  },
};
</script>

<style lang="scss">
@import "src/assets/styles/vars";

.phoenix-client {
  .modal-footer {
    .btn:hover,
    .btn:active,
    .btn:focus-visible {
      background-color: $modal-header-bg-color !important;
      color: #989898;
      transition: 0.7s !important;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "src/assets/styles/vars";

.dialog-header {
  color: #212327;
}
.btn-modal-close {
  position: absolute;
  right: -2px;
  border-radius: 3px;
  padding: 0px;
  top: -2px;
  width: 24px;
  height: 24px;
  background: white
    url("data:image/svg+xml,%3Csvg width='30' height='30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Crect width='30' height='30' rx='3' fill='%23fff'/%3E%3Cpath d='M23.75 8.012 21.988 6.25 15 13.238 8.012 6.25 6.25 8.012 13.238 15 6.25 21.988l1.762 1.762L15 16.762l6.988 6.988 1.762-1.762L16.762 15l6.988-6.988Z' fill='%233C5B74'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Crect width='30' height='30' rx='3' fill='%23fff'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E")
    no-repeat center center;
  background-size: contain;
}

.modal-dlg {
  margin: 0 auto;
  padding: 15px;
  border: solid 2px #ccc;
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  pointer-events: auto;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  &__hide-border {
    border: none;
  }
}

.modal-background {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  background: rgba(0, 0, 0, 0.32);
  opacity: 1;
  &__hide {
    background: transparent;
  }
}

.btn-dialog {
  background-color: $input-bg-color;
  color: $input-font-color;
  height: 42px;
  font-family: $input-font-family;
  font-size: $input-font-size;
  padding: 0 20px;

  :hover,
  :focus,
  :active {
    background-color: $item-selected-bg-color !important;
    transition: 0.7s;
  }
}

.btn-ok {
  background-color: $modal-header-bg-color;
  color: $modal-header-color;
  height: 42px;
  font-family: $input-font-family;
  font-size: $input-font-size;
  padding: 0 20px;
}

.modal-body {
  position: relative;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  color: $dialog-text-color;
  font-family: $font-roboto-medium;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.modal-footer {
  padding-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.modal-button-panel {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: flex-end;
  button {
    min-width: 100px;
  }
}

.vue-cs-right-modal {
  top: 0 !important;
  right: 0 !important;
  max-width: 425px;
  height: 100%;
  transform: none !important;
  left: auto;
}
</style>
