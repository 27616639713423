<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="12" fill="#186AFE" />
    <g clip-path="url(#clip0_60_5286)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1851 6.95331C16.3498 6.88397 16.5302 6.86006 16.7073 6.88405C16.8844 6.90805 17.0519 6.97909 17.1922 7.08977C17.3326 7.20044 17.4407 7.34672 17.5053 7.51337C17.57 7.68001 17.5888 7.86094 17.5598 8.03731L16.0478 17.2086C15.9011 18.0933 14.9304 18.6006 14.1191 18.16C13.4404 17.7913 12.4324 17.2233 11.5258 16.6306C11.0724 16.334 9.68375 15.384 9.85442 14.708C10.0011 14.13 12.3344 11.958 13.6678 10.6666C14.1911 10.1593 13.9524 9.86664 13.3344 10.3333C11.7991 11.492 9.33575 13.254 8.52109 13.75C7.80242 14.1873 7.42775 14.262 6.97975 14.1873C6.16242 14.0513 5.40442 13.8406 4.78575 13.584C3.94975 13.2373 3.99042 12.088 4.78509 11.7533L16.1851 6.95331Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_60_5286">
        <rect width="16" height="16" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
export default {
  name: "TelegramIcon",
};
</script>

<style scoped lang="scss"></style>
