import { defineStore } from "pinia";
import useCrudStore from "@/store/composables/crud";
import environment from "@/environment";
import useCancelTokenStore from "@/store/composables/cancel-token";
import axios from "axios";
import {
  FollowerCounterRecord,
  FollowerCounters,
} from "@/model/followes-history";

export const useFollowersHistoryStore = defineStore(
  "followers-history-statistic",
  () => {
    const crud = useCrudStore(
      environment.appConfig.baseApiUrl + "/statistic/followers/history"
    );
    const cancelToken = useCancelTokenStore();

    async function loadFollowersHistory(
      startDate: string,
      channelIds: string[] = null
    ) {
      console.log("loadFollowersHistory", startDate);
      cancelToken.acquireToken();
      crud.startLoading();
      const params = {
        start_date: startDate,
      };

      try {
        const data = (
          await axios.get(crud.endpoint.value, {
            cancelToken: cancelToken.cancelToken.value,
            params,
          })
        )?.data;

        const arr: FollowerCounters[] = [];
        for (const channelId in data?.items) {
          // Отбираю только для заданных каналов
          if (channelIds?.length && !channelIds.find((id) => id == channelId)) {
            continue;
          }
          const history: FollowerCounterRecord[] = [];
          const historyObject = data?.items[channelId];
          let counter = 0;
          let priorValue = null;
          for (const dateKey in historyObject) {
            let pubCount = +historyObject[dateKey];
            if (!pubCount) {
              if (priorValue === null) {
                continue;
              }
              pubCount = priorValue;
            }
            // Если -1, то ставлю 0 так как прирост неизвестен
            //
            const progressCount =
              priorValue === null ? 0 : pubCount - priorValue;

            counter += progressCount;

            history.push({
              actionDate: dateKey,
              counter: progressCount,
              progress: progressCount,
            });
            priorValue = pubCount;
          }
          arr.push({ channelId, counter, history });
        }
        crud.addItems(arr);
        crud.clearLoading();
      } catch (e) {
        crud.setError(e);
      }
    }

    return {
      ...crud,
      loadFollowersHistory,
    };
  }
);
