import { Channel } from "@/model/channel";
import { computed, ref, watch } from "vue";
import { ComputedRef } from "vue";

export default function useChannelFilter(items: ComputedRef<Channel[]>) {
  const selectedChannel = ref(0);
  const channelsIds = computed(() => {
    return items.value.map((item) => item.id);
  });

  watch(
    () => items,
    (currentValue) => {
      const found = currentValue.value.find(
        (item) => item.id == selectedChannel.value
      );
      if (!found) {
        selectedChannel.value = 0;
      }
    },
    { deep: true }
  );

  return {
    selectedChannel,
    channelsIds,
  };
}
