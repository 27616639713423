<template>
  <div class="login-box text-start">
    <div class="login-box-form">
      <div class="login-box-header">Глобус</div>
      <div class="row">
        <label class="p-0" for="loginInput">Логин</label>
        <input
          class="form-control"
          :class="{ error: usernameError }"
          id="loginInput"
          v-model="authStore.username"
          @keydown.enter="doLoginHandler"
        />
      </div>
      <div class="row">
        <label class="p-0" for="passwordInput">Пароль</label>
        <div class="position-relative p-0 m-0">
          <input
            class="form-control"
            :class="{ error: passwordError }"
            :type="inputType"
            id="passwordInput"
            style="padding-right: 32px"
            v-model="authStore.password"
            @keydown.enter="doLoginHandler"
          />
          <span class="eye-button" @click="changeInputTypeHandler">
            <b-icon-eye v-if="inputType === 'password'" />
            <b-icon-eye-slash v-else />
          </span>
        </div>
      </div>
      <div class="row">
        <button class="btn btn-primary" @click="doLoginHandler">Войти</button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useAuthStore } from "@/store/auth";

export default {
  name: "LoginBox",
  emits: ["login"],
  setup(props, { emit }) {
    const authStore = useAuthStore();
    const inputType = ref("password");
    const touched = ref(false);

    function doLoginHandler() {
      touched.value = true;
      if (passwordError.value || usernameError.value) {
        return;
      }
      emit("login");
    }

    onMounted(() => {
      touched.value = false;
    });

    function changeInputTypeHandler() {
      inputType.value = inputType.value === "password" ? "text" : "password";
    }

    const usernameError = computed(() => {
      return authStore.username === "" && touched.value;
    });

    const passwordError = computed(() => {
      return (authStore.password || "").length < 3 && touched.value;
    });

    return {
      inputType,
      changeInputTypeHandler,
      authStore,
      touched,
      usernameError,
      passwordError,
      doLoginHandler,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/vars";

.error {
  border: 1px solid red;
}

@media screen and (max-width: 620px) {
  .login-box-form {
    padding: 30px !important;
  }
  .login-box {
    width: 100vw !important;
    height: 330px !important;
    top: 30vh !important;
    position: relative;

    button {
      width: 100vw !important;
    }
  }
}

.login-box {
  width: 588px;
  height: 480px;
  position: absolute;
  background: white;
  top: 40vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  border-radius: 20px;
}

.login-box-form {
  padding: 90px 90px 28px 90px;

  label {
    height: 28px;
    font: $font-ag-body3;
  }

  input {
    margin-bottom: 28px;
  }

  button {
    width: 113px;
    height: 40px;
    font: $font-ag-body1;
  }
}

.login-box-header {
  font-family: $font-news-cycle-bold;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.eye-button {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
</style>
